import { Product, ColourVariation } from "@/types"

export const getProductBase = (product: Product | ColourVariation) => {
  if (product.product_type === "fabric") {
    return { path: "fabrics", name: "Fabrics" }
  }

  if (product.product_type === "tooling") {
    return { path: "tooling", name: "Tooling" }
  }

  if (product.product_type === "point-of-sale") {
    return { path: "point-of-sale", name: "Point of Sale" }
  }

  return { path: "components", name: "Components" }
}

export const getProductUrl = (product: Product | ColourVariation) => {
  const { path } = getProductBase(product)

  return `/${path}/${product.slug}/`
}

export const getProductDefaultQuantity = (product: Product) => {
  let quantity = 0

  if (["components"].includes(product.product_type)) {
    quantity = product.roll_length
  }

  if (["fabric"].includes(product.product_type)) {
    return ""
  }

  return quantity > 0 ? quantity : 1
}
